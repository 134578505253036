/* eslint-disable max-len */

import {Ensure, Helper} from "./common";
import './accordions';
import './modals';
import './inputs';
import './fixMenu';
import './overlays';
import './ripple';
import './copyPixQrCode';
import $ from 'jquery';

window.validateRecaptcha = (token) => {
    if ('' !== token) {
        document.querySelectorAll('.js-recaptchaButton').forEach(el => el.disabled = false);
    }
}

window.disableSubmitRecaptcha = _ => {
    document.querySelectorAll('.js-recaptchaButton').forEach(el => el.disabled = true);
}

window.cartMiniAjustHeight = function () {
    const cartMini = document.querySelector('.cartMini');
    if (cartMini) {
        const cartMiniHeader = cartMini.querySelector('.cartMini__header');
        const cartMiniMain = cartMini.querySelector('.cartMini__main');
        const cartMiniFooter = cartMini.querySelector('.cartMini__footer');
        const cartMiniFreeShipping = cartMini.querySelector('.cartMini .freeShipping');

        let height = window.innerHeight - (cartMiniHeader.getBoundingClientRect().height + cartMiniFooter.getBoundingClientRect().height);
        if (cartMiniFreeShipping) {
            height -= cartMiniFreeShipping.getBoundingClientRect().height;
        }

        cartMiniMain.style.height = `${height}px`;

        window.addEventListener('resize', e => {
            const cartMiniHeader = cartMini.querySelector('.cartMini__header');
            const cartMiniMain = cartMini.querySelector('.cartMini__main');
            const cartMiniFooter = cartMini.querySelector('.cartMini__footer');
            const cartMiniFreeShipping = cartMini.querySelector('.cartMini .freeShipping');

            let height = window.innerHeight - (cartMiniHeader.getBoundingClientRect().height + cartMiniFooter.getBoundingClientRect().height);
            if (cartMiniFreeShipping) {
                height -= cartMiniFreeShipping.getBoundingClientRect().height;
            }

            cartMiniMain.style.height = `${height}px`;
        });
    }
};

window.toggleMiniCartMobile = function () {
    const toggleCartClass = "action__cart";
    const bodyElement = document.querySelector("html");

    bodyElement.classList.toggle(toggleCartClass);

    window.cartMiniAjustHeight();
};

document.addEventListener('DOMContentLoaded', _ => {
    (async _ => {
        const stripbannerCountdown = $('[data-stripbanner-countdown]');

        if (stripbannerCountdown.length > 0) {
            const finalDate = stripbannerCountdown.data('stripbannerCountdown');

            stripbannerCountdown.countdown(finalDate, function (event) {
                stripbannerCountdown.html(event.strftime('%H:%M:%S'));
            });
        }
    })();

    /** Widget de busca */
    (async _ => {
        const QuickSearch = (await import('./components/quickSearch')).default;

        (new QuickSearch('.search__input', 3)).load();
    })();

    /** Lazysizes */
    (async _ => {
        await import('lazysizes');
        await import('lazysizes/plugins/unveilhooks/ls.unveilhooks');
        await import('lazysizes/plugins/parent-fit/ls.parent-fit');
    })();

    (async _ => {
        const leadForms = document.querySelectorAll('.js-leadEventos');

        [...leadForms].forEach(form => form.addEventListener('submit', async e => {
                const formData = new FormData(form)

                e.preventDefault();
                try {
                    const response = await fetch(
                        '/bebida/handle_lead_eventos.php',
                        {
                            method: 'POST',
                            body: formData
                        }
                    )
                    const data = await response.json()

                    if (data.ok) {
                        form.style.display = 'none';
                        form.closest('.newsletter').querySelector('.js-leadEventosSuccess').classList.add('newsletter__message--success');
                    }
                    form.querySelector('[name="leadEventos-email"]').value = '';
                } catch (e) {
                    console.error(e);
                }
            })
        );
    })();

    (async _ => {
        const delegate = (await import('delegate')).default;

        delegate(document.querySelector('body'), '.js-warningBubble', 'click', e => {
            const warningBubble = e.delegateTarget;

            warningBubble.classList.toggle('warning-bubble--dismissed');
        }, false);
    })();
});

class App {
    constructor() {
        this.eventHandlerBinds();
    }

    /**
     * Application event handler binds
     */
    eventHandlerBinds() {
        // Page's DOM content is loaded
        document.addEventListener("DOMContentLoaded", event => {
            this.menuHandler();
            this.cartHandler();
            this.eventHandlerShowPassowrd();
            this.menuHamburguer();
            this.setHandleSubcategoriesHover();
            this.setHandleCategoriesHover();
            this.cartMiniHeight();
            this.cartMiniHeightTwo();
            this.cancelPayPalBillingAgreement();
            this.workingDaysHandler();
            this.totemPopupHandler();
            this.cookieBannerConsentHandler();
            this.newsletterHandler();
            this.stockPopupHandler();
            this.storiesHandler();
        });
    }


    setHandleSubcategoriesHover() {
        const subcategoriesProducts = {};

        const subcategories = document.querySelectorAll('.categoriesMenu__category__expanded__list__item');

        [...subcategories].forEach(subcategory => {
            subcategory.addEventListener('mouseover', ({target}) => {
                const {skuId} = subcategory.dataset;
                const endpoint = `/bebida/get_product_by_id.php?sku_id=${skuId}`;

                const categoryExpanded = Helper.getParents(target, '.categoriesMenu__category__expanded')[0];

                const categoryExpandedHighlight = categoryExpanded.querySelector('.categoriesMenu__category__expanded__highlight');
                if (categoryExpandedHighlight) {
                    if (subcategoriesProducts.hasOwnProperty(skuId)) {
                        categoryExpandedHighlight.innerHTML = subcategoriesProducts[skuId];
                    } else {
                        $.get(endpoint, data => {
                            categoryExpandedHighlight.innerHTML = subcategoriesProducts[skuId] = data;
                        });
                    }
                }
            });
        });
    }

    setHandleCategoriesHover() {
        const categories = document.querySelectorAll('.categoriesMenu__category');

        [...categories].forEach(category => {
            if (!category.classList.contains('filter-this')) {
                category.addEventListener('mouseover', ({target}) => {
                    const menuHamburger = document.querySelectorAll('.filter-this .menuHamburger__opened');
                    const menuButtonOpen = document.querySelector('.categoriesMenu__button-open');
                    if (menuHamburger.length > 0) {
                        menuButtonOpen.click();
                    }
                });
            }
        });
    }

    cartMiniHeight() {
        let vh = window.innerHeight * 0.01;
        let vw = window.innerWidth - document.documentElement.clientWidth;

        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.documentElement.style.setProperty('--vw', `${vw}px`);

        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        })
    }

    cartMiniHeightTwo() {
        window.cartMiniAjustHeight();
    }

    menuHandler() {
        const hamburguerElement = document.querySelector(".js-hamburguer");
        const menuUtilHandler = document.querySelectorAll(".js-menuUtil");
        const bodyElement = document.querySelector("html");
        const menuDadElement = document.querySelectorAll(".menu__container .js-menuDad");
        const menuAa = document.querySelectorAll('.no_preDef');
        const menuSubBackElement = document.querySelectorAll(".js-menuBack");
        const menuRecurrency = document.querySelector('.js-recurrencyMenu');
        const toggleMenuClass = "action__menu";

        if (!hamburguerElement) {
            return;
        }

        hamburguerElement.addEventListener("click", e => {
            bodyElement.classList.toggle(toggleMenuClass);
        });

        document.querySelector(".js-menuAccount")?.addEventListener('click', e => {
            const currentMenuSub = e.currentTarget.closest('.js-menuDad').querySelector('.menu__sub');

            currentMenuSub.classList.toggle("menu__sub--active");

            [...document.querySelectorAll('.menu__sub--active')].forEach(active => {
                if (!active.isSameNode(currentMenuSub)) {
                    active.classList.remove('menu__sub--active')
                }
            });
        });

        document.querySelector(".menu__header .menu__sub")?.addEventListener('transitionend', e => {
            const menuEl = e.currentTarget.closest('.menu-account-links');

            menuEl.style.zIndex = (e.currentTarget.classList.contains('menu__sub--active') ? '1' : '-1');
        })

        document.querySelector(".js-menuAccountBack")?.addEventListener('click', e => {
            e.currentTarget.closest('.menu__sub').classList.remove('menu__sub--active');
        });

        menuRecurrency?.addEventListener('click', e => {
            const clicked = e.currentTarget;

            e.preventDefault();

            clicked.classList.toggle('recurrencyMenu--open')
        });

        [...menuUtilHandler].map(item => {
            item.addEventListener("click", e => {
                e.preventDefault();

                bodyElement.classList.toggle(toggleMenuClass);
            });
        });

        [...menuAa].map(itemAa => {
            itemAa.addEventListener("click", e => {
                e.preventDefault();

                itemAa.getAttribute('href');
                setTimeout(() => location.href = itemAa, 1);
            });
        });

        [...menuDadElement].map(item => {
            item.addEventListener("click", e => {
                e.preventDefault();
                const currentMenuSub = item.querySelector('.menu__sub');

                currentMenuSub.classList.toggle("menu__sub--active");

                [...document.querySelectorAll('.menu__sub--active')].forEach(active => {
                    if (!active.isSameNode(currentMenuSub)) {
                        active.classList.remove('menu__sub--active')
                    }
                });
            });
        });
    }

    cartHandler() {
        const cartElement = document.querySelector(".js-cart");
        const cartCloseElement = document.querySelector(".js-cartClose");

        const bodyElement = document.querySelector("html");
        const toggleCartClass = "action__cart";

        if (!Ensure.isUndefined(cartElement)) {
            cartElement.addEventListener("click", e => {
                e.preventDefault();
                bodyElement.classList.toggle(toggleCartClass);
                window.cartMiniAjustHeight();
            });

            cartCloseElement.addEventListener("click", e => {
                e.preventDefault();
                bodyElement.classList.toggle(toggleCartClass);
            });
        }

    }

    eventHandlerShowPassowrd() {
        const showPasswordButtons = document.querySelectorAll('.js-showPassword');

        if (showPasswordButtons[0]) {
            [...showPasswordButtons].forEach(button => {
                button.addEventListener('click', event => {
                    event.preventDefault();

                    const {parentElement} = event.target.parentElement;
                    const inputPassword = parentElement.querySelector('.js-passwordInput');

                    if ('password' === inputPassword.type) {
                        inputPassword.setAttribute('type', 'text');
                    } else {
                        inputPassword.setAttribute('type', 'password');
                    }
                    parentElement.querySelector('.toggle-password--hidden').classList.remove('toggle-password--hidden');
                    event.target.classList.add("toggle-password--hidden");
                });
            });
        }
    }

    menuHamburguer() {
        const menuButtonOpen = document.querySelector('.categoriesMenu__button-open');

        if (menuButtonOpen) {
            menuButtonOpen.addEventListener('click', event => {
                const icon = menuButtonOpen.children[0];
                const overlay = document.querySelector('.overlay');
                const headerDropdownContainer = menuButtonOpen.nextElementSibling;
                const {maxHeight} = window.getComputedStyle(headerDropdownContainer);
                const showContainer = maxHeight === '0px';

                headerDropdownContainer.style.maxHeight = showContainer ? '90vh' : 0;


                if (showContainer) {
                    icon.classList.add('fa-times');
                    icon.classList.remove('fa-bars');
                    menuButtonOpen.classList.add('menuHamburger__opened');
                } else {
                    icon.classList.add('fa-bars');
                    icon.classList.remove('fa-times');
                    menuButtonOpen.classList.remove('menuHamburger__opened');
                }

                showContainer
                    ? overlay.classList.add('overlay--show', 'overlay--not-hidden')
                    : overlay.classList.remove('overlay--show', 'overlay--not-hidden');
            });
        }
    }

    cancelPayPalBillingAgreement() {
        const cancelPaypalBillingAgreementButton = document.querySelectorAll('.js-cancelBillingAgreement');

        if (cancelPaypalBillingAgreementButton) {
            [...cancelPaypalBillingAgreementButton].map(button => {
                button.addEventListener('click', event => {
                    let clickedButton = button;

                    clickedButton.innerHTML = 'Cancelando&nbsp;&nbsp;<i class="loading-icon far fa-spin fa-spinner-third"></i>';
                    fetch('/bebida/paypal.php?action=cancel-billing-agreement', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                    }).then(function (response) {
                        clickedButton.innerHTML = 'Acordo Cancelado';
                        setTimeout(function () {
                            clickedButton.parentNode.removeChild(clickedButton);
                        }, 5000);
                    }).catch(function (response) {
                        clickedButton.innerHTML = 'Cancelar Acordo';
                    });
                });
            });
        }
    };

    workingDaysHandler() {
        const bodyElement = document.querySelector("html");
        const toggleWorkingDaysClass = "action__workingDays";

        const workingDaysElement = document.querySelector(".js-workingDays");
        const workingDaysCloseElement = document.querySelector(".js-workingDaysModal");
        const workingDaysUtilElement = document.querySelector(".js-workingDaysUtil");

        if (workingDaysElement) {
            document.querySelectorAll('.js-workingDays').forEach(el => {
                el.addEventListener("click", e => {
                    const workingDayRegular = document.querySelector('.workingDays__regular');
                    const workingDayAlternative = document.querySelector('.workingDays__alternative');

                    e.preventDefault();

                    workingDayRegular.classList.remove('hidden');
                    workingDayAlternative.classList.remove('hidden');

                    if (e.currentTarget.dataset?.workingDaysAlternative === '1') {
                        workingDayRegular.classList.add('hidden');
                    } else {
                        workingDayAlternative.classList.add('hidden');
                    }
                    bodyElement.classList.toggle(toggleWorkingDaysClass);
                });
            });


            workingDaysCloseElement.addEventListener("click", e => {
                e.preventDefault();

                bodyElement.classList.remove(toggleWorkingDaysClass);
            });

            workingDaysUtilElement.addEventListener("click", e => {
                e.preventDefault();

                bodyElement.classList.remove(toggleWorkingDaysClass);
            });
        }

    }

    totemPopupHandler() {
        const bodyElement = document.querySelector("html");
        const toggleTotemPopupClass = "action__totemPopup";
        const totemPopupElement = document.querySelector(".totemPopup");

        if (totemPopupElement) {
            (async _ => {
                window.inactivityListener = (await import('inactivity-listener')).default;

                const totemPopupCountdown = document.querySelector(".totemPopup__countdown");
                const totemPopupCountdownTime = totemPopupElement.dataset.redirectCountdown;
                const totemPopupTimeout = totemPopupElement.dataset.timeout - totemPopupCountdownTime;

                let countdownTimer;

                const countdown = () => {
                    clearInterval(countdownTimer);
                    setTimeout(
                        () => {
                            let tick = totemPopupCountdownTime;

                            bodyElement.classList.add(toggleTotemPopupClass);
                            countdownTimer = setInterval(() => {
                                totemPopupCountdown.innerHTML = new Date(tick * 1000).toISOString().slice(14, 19);
                                if (tick <= 0) {
                                    clearInterval(countdownTimer);
                                    window.location.replace("/bebida/logoff.php");
                                } else {
                                    tick--;
                                }
                            }, 1000, tick);
                        },
                        1000 * totemPopupTimeout,
                        countdown
                    );
                }

                window.inactivityListener.start(1000 * totemPopupTimeout, countdown);

                totemPopupElement.addEventListener("click", e => {
                    e.preventDefault();

                    totemPopupCountdown.innerHTML = new Date(totemPopupCountdownTime * 1000).toISOString().slice(14, 19);
                    bodyElement.classList.remove(toggleTotemPopupClass);
                    window.inactivityListener.restart();
                    clearInterval(countdownTimer);
                });
            })();
        }
    }

    cookieBannerConsentHandler() {
        const cookieBannerConsenButton = document.querySelectorAll('.js-cookieBannerConsent');

        if (cookieBannerConsenButton) {
            [...cookieBannerConsenButton].map(button => {
                button.addEventListener('click', event => {

                    fetch('?cookie-consent=1', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                    }).then(function (response) {
                        event.target.closest('.cookieBannerConsent').classList.add('cookieBannerConsent--accepted');
                    }).catch(function (response) {
                    });

                    event.preventDefault();
                });
            });
        }
    };

    newsletterHandler() {
        const form = document.querySelector('.js-newsletter');

        form?.addEventListener('submit', e => {
            const formData = new FormData(e.target)

            fetch(
                '/bebida/handle_newsletter.php',
                {
                    method: 'POST',
                    body: formData
                })
                .then(res => res.json())
                .then(data => {
                    if (data.ok) {
                        form.style.display = 'none';
                        document.querySelector('.js-newsletterSuccess').classList.add('newsletter__message--success');
                    }
                    form.querySelector('[name="newsletter-email"]').value = '';
                })
                .catch(error => {
                    form.querySelector('[name="newsletter-email"]').value = '';
                    console.error(error);
                })
            ;
            e.preventDefault();
        })
    };

    stockPopupHandler() {
        const changePostalcode = document.querySelectorAll('.js-changePostalcode');

        [...changePostalcode].forEach(el => el.addEventListener('click', e => {
            $.fancybox.open({
                src: '/bebida/popup_stock.php',
                modal: true,
                type: 'ajax',
                afterClose: function () {
                    window.location.reload();
                }
            });

            e.preventDefault();
        }));
    }

    storiesHandler() {
        document.querySelector('.stories__logo')?.addEventListener('click', e => {
            const storiesBox = document.querySelector('.stories');

            fetch('/bebida/render_stories.php')
                .then(res => res.text())
                .then(html => {
                    const storiesContent = document.querySelector('.stories__content');
                    storiesBox.classList.add('stories--shown')
                    storiesContent.innerHTML = html;

                    const slidesContainer = storiesContent.querySelector('.stories__slides');
                    const qtySlides = parseInt(getComputedStyle(slidesContainer).getPropertyValue('--qty'));
                    const pills = Array.from(document.querySelectorAll('.stories__pill'));
                    const moveTo = (to) => {
                        /** "to" start with 1 */
                        [...pills].forEach((el, i) => {
                            el.classList.remove('active', 'passed');
                            if (i < to - 1) {
                                el.classList.add('passed');
                            }
                        });
                        slidesContainer.scrollTo({
                            top: 0,
                            left: (to - 1) * slidesContainer.offsetWidth,
                            behavior: "smooth"
                        });
                        pills[to - 1]?.classList.add('active');
                        if (to === qtySlides) {
                            pills[qtySlides - 1].addEventListener(
                                'animationend',
                                _ => storiesBox.classList.remove('stories--shown')
                            );
                        }
                    };
                    const slide = _ => {
                        moveTo(currentSlide + 1);
                        currentSlide++;
                    };
                    let currentSlide = 1;

                    setTimeout(_ => {
                        slidesContainer.scrollTo({
                            top: 0,
                            left: 0
                        });
                    }, 100);

                    [...pills].forEach(el => el.addEventListener('animationend', slide));
                    [...storiesContent.querySelectorAll('.stories__prev, .stories__next, .stories__pill')].forEach(el => {
                        el.addEventListener('click', e => {
                            const activePill = parseInt(document.querySelector('.stories__pill.active')?.dataset.pill) || qtySlides;

                            switch (true) {
                                case e.currentTarget.classList.contains('stories__prev'):
                                    if (activePill >= 2) {
                                        moveTo(activePill - 1);
                                        currentSlide = activePill - 1;
                                    }
                                    break;

                                case e.currentTarget.classList.contains('stories__next'):
                                    if (activePill < qtySlides) {
                                        moveTo(activePill + 1);
                                        currentSlide = activePill + 1;
                                    } else {
                                        storiesBox.classList.remove('stories--shown');
                                    }
                                    break;

                                case e.currentTarget.classList.contains('stories__pill'):
                                    moveTo(parseInt(e.currentTarget.dataset.pill));
                                    currentSlide = parseInt(e.currentTarget.dataset.pill);
                                    break;

                                default:
                                    break;
                            }

                            e.stopImmediatePropagation();
                        });
                        el.addEventListener('touchstart', e => {
                            storiesContent.classList.add('paused');
                            document.querySelector('body').classList.add('no-user-selection');
                            e.stopImmediatePropagation();
                        });
                        el.addEventListener('touchend', _ => {
                            storiesContent.classList.remove('paused', 'show-prev-next');
                            document.querySelector('body').classList.remove('no-user-selection');
                        });
                        el.addEventListener('touchcancel', _ => {
                            storiesContent.classList.remove('paused', 'show-prev-next');
                            document.querySelector('body').classList.remove('no-user-selection');
                        });
                        el.addEventListener('touchmove', _ => storiesContent.classList.add('show-prev-next'));
                    });
                    document.querySelector('.stories__backdrop').addEventListener('click', e => {
                        storiesBox.classList.remove('stories--shown');
                        storiesContent.classList.remove('paused', 'show-prev-next');
                        document.querySelector('body').classList.remove('no-user-selection');

                        e.stopImmediatePropagation();
                    });

                    storiesBox.classList.remove('stories--with-new');
                })
                .catch(error => {
                    console.error(error);
                })
            ;
        })
    }
}

new App();
